import * as React from 'react';
import {MDBContainer, MDBRow} from "mdb-react-ui-kit";
import SectionComponent, {SectionProps} from "./sectionComponent";
import "./centeredComponent.scss";


export default class CenteredComponent extends SectionComponent<SectionProps> {
  public render() {
    const options = this.props.data.frontmatter;

    return (
      <section id={options.sectionId ?? undefined}>
        {
          (options.separatorTop == true ? (<div className="section-separator"/>) : <div/>)
        }
        <MDBContainer className="centered-section py-0 py-md-5" fluid={options.containerFluid}>

          <MDBRow className="text-center py-5">
            {this.renderContent("12")}
          </MDBRow>

        </MDBContainer>
      </section>
    );
  }
}


