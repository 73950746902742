import * as React from 'react';
import {MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBRow} from "mdb-react-ui-kit";
import SectionComponent, {BootstrapColumnWidth, SectionProps} from "./sectionComponent";
import {MDXRenderer} from "gatsby-plugin-mdx";
import {GlobalContentQuery} from "../../lib/getAllContent";
import {graphql, Link, StaticQuery, StaticQueryProps} from "gatsby";

interface IntroProps extends SectionProps {
  global: GlobalContentQuery
}

interface HomepageServicesQuery {
  allFile : {
    nodes: HomepageService[]
  }
}

interface HomepageService {
  id: string,
  childMdx: {
    body: any
    frontmatter: {
      order: number,
      linkText: string,
      linkTarget: string
    }
  }
}

export default class IntroComponent extends SectionComponent<IntroProps> {
  public render() {
    return (
      <section id={this.props.data.frontmatter.sectionId ?? undefined} className="">
        <MDBContainer className="py-3 py-md-5">
          <MDBRow className="align-items-center py-4 py-md-5">
            {this.renderContent("4")}
            {this.renderImage("8")}
          </MDBRow>
          <StaticQuery query={servicesQuery} render={this.renderServices}/>
        </MDBContainer>
      </section>
    );
  }

  protected renderContent(mdColWidth: BootstrapColumnWidth = "6"): JSX.Element {
    const {body} = this.props.data;

    return (
      <MDBCol md={mdColWidth} className="content-col mb-5 mb-sm-0">
        <MDXRenderer>{body}</MDXRenderer>
      </MDBCol>
    );
  }

  protected renderServices(queryResult: HomepageServicesQuery) : JSX.Element {
    return (
      <MDBRow>
        {queryResult.allFile.nodes.map((service) => {
          return (
            <MDBCol key={service.id}  className="col-12 col-sm-6 col-md-3 my-2">
              <MDBCard className="h-100">
                <MDBCardBody className="d-flex flex-column">
                  <div className="h-100 mb-0">
                    <MDXRenderer>{service.childMdx.body}</MDXRenderer>
                  </div>
                  {
                    service.childMdx.frontmatter.linkText
                    &&
                    <Link to={service.childMdx.frontmatter.linkTarget}>
                      <MDBBtn size="sm" color="primary" className="m-0">{service.childMdx.frontmatter.linkText}</MDBBtn>
                    </Link>
                  }
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          )
        })}
      </MDBRow>
    )
  }
}


const servicesQuery = graphql`
  query {
    allFile(
      filter: {extension: {eq: "mdx"}, relativeDirectory: {eq: "homepage-services"}}
      sort: {fields: childrenMdx___frontmatter___order, order: ASC}
    ) {
      nodes {
        id
        childMdx {
          body
          frontmatter {
            order
            linkTarget
          }
        }
      }
    }
  }`;