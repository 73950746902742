import * as React from 'react';
import {MDBCard, MDBCol, MDBContainer, MDBRow} from "mdb-react-ui-kit";
import SectionComponent, {SectionProps} from "./sectionComponent";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import "./jumbotronComponent.scss";
import {MDXRenderer} from "gatsby-plugin-mdx";

export default class JumbotronComponent extends SectionComponent<SectionProps> {
  readonly image = getImage(this.props.data.frontmatter.backgroundImage)!;
  readonly imageMobile = getImage(this.props.data.frontmatter.backgroundImageMobile);

  public render() {
    const data = this.props.data;

    return (
      <section id={data.frontmatter.sectionId ?? undefined}>
        <MDBContainer className="jumbotron-section text-white">
          <MDBRow className="text-center">
            <MDBCol size="11" className="mx-auto">
              <MDBCard>
                <GatsbyImage alt={""} image={this.image} className="background-image d-none d-md-block"/>
                {
                  this.imageMobile ? <GatsbyImage alt={""} image={this.imageMobile} className="background-image d-block d-md-none"/> : <></>
                }
                <MDBCol className="py-4 px-3 p-md-5">
                  <MDXRenderer>{data.body}</MDXRenderer>
                </MDBCol>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    );
  }
}


