import * as React from 'react';
import Layout from "../components/layout";
import getHomepageSections from "../lib/getHomepageSections";
import IntroComponent from "../components/homepage/introComponent";
import SideBySideComponent from "../components/homepage/sideBySideComponent";
import CenteredComponent from "../components/homepage/centeredComponent";
import getGlobalContent from "../lib/getAllContent";
import JumbotronComponent from "../components/homepage/jumbotronComponent";

const IndexPage = () => {
  const sections = getHomepageSections();
  const globalData = getGlobalContent();

  return (
    <Layout description={globalData.file.childMdx.frontmatter.homepageSEODescription}>
      {sections.allFile.nodes.map((section) => {
        switch (section.childMdx.frontmatter.type) {
          case "intro":
            return <IntroComponent data={section.childMdx} global={globalData} key={section.id} />;
          case "side-by-side":
            return <SideBySideComponent data={section.childMdx} key={section.id} />;
          case "jumbotron":
            return <JumbotronComponent data={section.childMdx} key={section.id}/>
          case "centered":
          default:
            return <CenteredComponent data={section.childMdx} key={section.id} />;
        }
      })}
    </Layout>
  )
}

export default IndexPage