import * as React from 'react';
import {HomepageSectionNode} from "../../lib/getHomepageSections";
import {MDBCol, MDBContainer, MDBRow} from "mdb-react-ui-kit";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {MDXRenderer} from "gatsby-plugin-mdx";
import "./sectionComponent.scss";

export interface SectionProps {
  data: HomepageSectionNode
}

export default abstract class SectionComponent<T extends SectionProps> extends React.Component<T> {
  protected renderContent(mdColWidth : BootstrapColumnWidth = "5") {
    const {body} = this.props.data;

    return (
      <MDBCol md={mdColWidth} className="content-col">
        <MDXRenderer>
          {body}
        </MDXRenderer>
      </MDBCol>
    );
  }

  protected renderImage(mdColWidth: BootstrapColumnWidth = "7") {
    const {sectionImage, sectionImageAlt} = this.props.data.frontmatter;
    let image = getImage(sectionImage)!;
    image.backgroundColor = '#fff';

    return (
      <MDBCol md={mdColWidth} className="d-flex image-col mb-4 mb-md-0">
        <GatsbyImage alt={sectionImageAlt} image={image} className="mx-auto"/>
      </MDBCol>
    );
  }
}

export type BootstrapColumnWidth = "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "10" | "11" | "12";


