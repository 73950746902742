import * as React from 'react';
import {MDBContainer, MDBRow} from "mdb-react-ui-kit";
import SectionComponent, {SectionProps} from "./sectionComponent";

export default class SideBySideComponent extends SectionComponent<SectionProps> {
  public render() {
    const {frontmatter} = this.props.data;

    return (
      <section id={frontmatter.sectionId ?? undefined} className="mobile-image-first">
        <MDBContainer>
          <span id={frontmatter.id} className="d-none"> </span>
          <MDBRow className="align-items-center py-4">
            {frontmatter.switchSides ? this.renderImage() : this.renderContent()}
            {frontmatter.switchSides ? this.renderContent() : this.renderImage()}
          </MDBRow>
        </MDBContainer>
      </section>
    );
  }


}


