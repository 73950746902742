import {graphql, useStaticQuery} from "gatsby";
import {ImageDataLike} from "gatsby-plugin-image";

export interface HomepageSectionNode {
  frontmatter: {
    id: string,
    order: number,
    type: "intro" | "side-by-side" | "centered" | "jumbotron"
    switchSides?: boolean,
    sectionImage: ImageDataLike,
    sectionImageAlt: string,
    backgroundImage: ImageDataLike,
    backgroundImageMobile: ImageDataLike,
    separatorTop?: boolean,
    containerFluid?: boolean,
    sectionId?: string
  },
  body: any
}

export interface HomepageSectionsQuery {
  allFile: {
    nodes: [{
      id: string,
      childMdx: HomepageSectionNode
    }]
  }
}

export default function getHomepageSections() : HomepageSectionsQuery {
  return useStaticQuery(graphql`
  query {
    allFile(
      filter: {sourceInstanceName: {}, relativeDirectory: {eq: "homepage-sections"}, extension: {eq: "mdx"}}
      sort: {fields: childrenMdx___frontmatter___order, order: ASC}
    ) {
      nodes {
        id
        childMdx {
          frontmatter {
            id
            order
            type
            switchSides
            sectionImage {
              childImageSharp {
                gatsbyImageData
              }
            }
            sectionImageAlt 
            backgroundImage {
              childImageSharp {
                gatsbyImageData
              }
            }
            backgroundImageMobile {
              childImageSharp {
                gatsbyImageData
              }
            }
            separatorTop
            containerFluid
            sectionId
          }
          body
        }
      }
    }
  }
`);
}